<template>
  <div id="account" class="documents">
    <div class="form-container">
      <div v-if="messagesList && messagesList.length > 0">
          <ul v-if="isNesting">
              <template v-for="(message, ndx) in messagesList">
                <li
                    class="h2"
                    :key="message.id+'title'"
                    v-if="message.fond && (ndx === 0 || message.fond.name!==(messagesList[ndx-1] ? messagesList[ndx-1].fond.name : '') )"
                >
                  <h2>{{message.fond.name}}</h2>
                </li>
                <rwm-message
                        :key="message.id"
                        :message-id="message.id"
                        :date="message.created_at"
                        :files="message.files ? message.files : null"
                        :is-new="!message.read"
                        :is-important="message.important"
                        :text="message.body"
                        :title="message.subject"
                        @read="$emit('read', $event)"
                        @download="$emit('download', $event)"
                />
            </template>
          </ul>
          <ul v-else>
            <rwm-message
                v-for="message in messagesList"
                :key="message.id"
                :message-id="message.id"
                :date="message.created_at"
                :files="message.files ? message.files : null"
                :is-new="!message.read"
                :is-important="message.important"
                :text="message.body"
                :title="message.subject"
                @read="$emit('read', $event)"
                @download="$emit('download', $event)"
            />
          </ul>
      </div>
      <p v-else class="anketa">У вас нет сообщений в этой категории</p>
    </div>
  </div>
</template>

<script>
import RwmMessage from "../../../RWMMessage/RwmMessage";

export default {
  name: "MessagesPage",
  components: {RwmMessage},
  props: {
    title: String,
    messagesList: Array,
    isNesting: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    tempTitle: ""
  }),
  methods: {
    fundTitle(title) {
      this.tempTitle = title
      return title
    }
  }
}
</script>

<style scoped lang="scss">
ul {
  list-style-type: none;
  padding: 0;
  margin-top: 0;
  & .h2 {
    font-weight: bold;
    font-size: 25px;
    line-height: 34px;
    color: $RWM-dark;
    margin: 24px 0;
    &:first-child {
      margin-top: 0;
    }
  }
}
</style>
